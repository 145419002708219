// export const environment = {
//   production: false,
//   psc_formId: 'f1f27878-7d13-47db-979e-447c2223afeb',
//   sap_id: 'USR_20200189',
//   azureIdentityProvider: 'SKF-EMail-Users-Sign-In',
//   azureScope: 'aws.cognito.signin.user.admin email openid phone profile',
//   baseURL: 'https://devlinkapi.skfilluminate.net/admin/',
//   functionBaseURL: 'https://devlinkjobfunctionapi.skfilluminate.net/admin/',
//   timeOffBaseURL: 'https://devlinkkronosapi.skfilluminate.net/admin/',
//   localeURL: 'https://bw4iyjv3hb.execute-api.us-east-1.amazonaws.com/dev/admin/',
//   hostedUiURL: `https://appsdev-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=13ojrikjgp5vj57vbfb8n9d4mj&response_type=code&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://linkdev2.skfilluminate.net`,
//   cognitoAuthURL: 'https://appsdev-skfilluminate.auth.us-east-1.amazoncognito.com/oauth2/token',
//   newsStoryAPI: 'https://devlinknewshubapi.skfilluminate.net/admin/',
//   SKFAppSecUsersLink: `https://appsdev.skfilluminate.net/application-security/user`,
//   SKFAppSecGroupsLink: `https://appsdev.skfilluminate.net/application-security/group`,
//   SKFAppSecJobFunctionsLink: `https://appsdev.skfilluminate.net/application-security/group`,
//   orgAnnouncementBaseUrl: 'https://devlinkannouncementschedulerapi.skfilluminate.net/admin/',
//   colleagueCornerBaseUrl: 'https://devlinkcolleagueschedulerapi.skfilluminate.net/admin/',
//   pscBaseUrl: 'https://devlinkukgapi.skfilluminate.net/admin/',
//   smsReportBaseURL: 'https://devlinknotificationapi.skfilluminate.net/admin/'
// };

export const environment = {
  production: false,
  psc_formId: 'f1f27878-7d13-47db-979e-447c2223afeb',
  sap_id: 'USR_20200189',
  azureIdentityProvider: 'SKF-EMail-Users-Sign-In',
  azureScope: 'aws.cognito.signin.user.admin email openid phone profile',
  baseURL: 'https://uatlinkapi.skfilluminate.net/admin/',
  functionBaseURL: 'https://uatlinkjobfunctionapi.skfilluminate.net/admin/',
  timeOffBaseURL: 'https://uatlinkkronosapi.skfilluminate.net/admin/',
  localeURL: 'https://bw4iyjv3hb.execute-api.us-east-1.amazonaws.com/dev/admin/',
  hostedUiURL: `https://appsuat-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=5gda4tr0esitjadn5q85kqqcv1&response_type=code&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://linkuat.skfilluminate.net`,
  cognitoAuthURL: 'https://appsuat-skfilluminate.auth.us-east-1.amazoncognito.com/oauth2/token',
  newsStoryAPI: 'https://uatlinknewshubapi.skfilluminate.net/admin/',
  SKFAppSecUsersLink: `https://appsuat.skfilluminate.net/application-security/user`,
  SKFAppSecGroupsLink: `https://appsuat.skfilluminate.net/application-security/group`,
  SKFAppSecJobFunctionsLink: `https://appsuat.skfilluminate.net/application-security/group`,
  orgAnnouncementBaseUrl: 'https://uatlinkannouncementschedulerapi.skfilluminate.net/admin/',
  colleagueCornerBaseUrl: 'https://uatlinkcolleagueschedulerapi.skfilluminate.net/admin/',
  pscBaseUrl: 'https://uatlinkukgapi.skfilluminate.net/admin/',
  smsReportBaseURL: 'https://uatlinknotificationapi.skfilluminate.net/admin/'
};

// export const environment = {
//   production: true,
//   psc_formId: 'f1f27878-7d13-47db-979e-447c2223afeb',
//   sap_id: 'USR_20200189',
//   azureIdentityProvider: 'SKF-EMail-Users-Sign-In',
//   azureScope: 'aws.cognito.signin.user.admin email openid phone profile',
//   baseURL: 'https://prodlinkapi.skfilluminate.net/admin/',
//   functionBaseURL: 'https://prodlinkjobfunctionapi.skfilluminate.net/admin/',
//   timeOffBaseURL: 'https://prodlinkkronosapi.skfilluminate.net/admin/',
//   localeURL: 'https://bw4iyjv3hb.execute-api.us-east-1.amazonaws.com/dev/admin/',
//   hostedUiURL: `https://apps-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=7o18d4eh9f29i6d0753tdvuns4&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://link.skfilluminate.net`,
//   cognitoAuthURL: 'https://apps-skfilluminate.auth.us-east-1.amazoncognito.com/oauth2/token',
//   newsStoryAPI: 'https://prodlinknewshubapi.skfilluminate.net/admin/',
//   SKFAppSecUsersLink: `https://apps.skfilluminate.net/application-security/user`,
//   SKFAppSecGroupsLink: `https://apps.skfilluminate.net/application-security/group`,
//   SKFAppSecJobFunctionsLink: `https://apps.skfilluminate.net/application-security/group`,
//   orgAnnouncementBaseUrl: 'https://prodlinkannouncementschedulerapi.skfilluminate.net/admin/',
//   colleagueCornerBaseUrl: 'https://prodlinkcolleagueschedulerapi.skfilluminate.net/admin/',
//   pscBaseUrl: 'https://prodlinkukgapi.skfilluminate.net/admin/',
// };
